<template>
  <form @submit.prevent="sendPasswordResetEmail">
    <div class="modal-card">
      <header class="modal-card-head has-background-primary">
        <p class="modal-card-title has-text-white has-text-centered">
          Forgotten password?
        </p>
      </header>

      <section class="modal-card-body">
        <p class="has-margin-bottom-100">
          Enter your email below and we'll send you instructions on how to reset
          your password.
        </p>

        <div>
          <b-field label="Email">
            <b-input
              v-model="form.email"
              :disabled="processing"
              required
              type="email"
              placeholder="Your email"
            />
          </b-field>
        </div>
      </section>

      <footer class="modal-card-foot has-content-justified-between">
        <button
          :disabled="processing"
          type="button"
          class="button is-light"
          tabindex="-1"
          @click="$emit('close')"
        >
          Cancel
        </button>

        <button
          :class="{ 'is-loading': processing }"
          :disabled="formIsDisabled"
          type="submit"
          class="button is-success"
        >
          Send email
        </button>
      </footer>
    </div>
  </form>
</template>

<script>
export default {
  name: "ForgottenPasswordModal",
  props: {
    email: {
      type: String,
      required: false,
      default: ""
    }
  },
  data() {
    return {
      processing: false,
      form: {
        email: ""
      }
    };
  },
  computed: {
    formIsValid() {
      if (!this.$v.isEmail(this.form.email)) return false;
      return true;
    },
    formIsDisabled() {
      return !this.formIsValid || this.processing;
    }
  },
  created() {
    this.form.email = this.email || this.form.email;
  },
  methods: {
    sendPasswordResetEmail() {
      if (this.formIsDisabled) return;
      this.processing = true;
      this.$store
        .dispatch("auth/sendPasswordResetEmail", this.form)
        .then(result => {
          this.$emit("close");
          this.$toast.open({
            message: result.message,
            position: "is-bottom",
            queue: false
          });
        })
        .catch(message => {
          this.processing = false;
          this.$toast.open({
            message: `${message}`,
            position: "is-bottom",
            type: "is-danger",
            queue: false
          });
        });
    }
  }
};
</script>
